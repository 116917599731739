






































































































import { defineComponent } from "@vue/composition-api";
export default defineComponent({
  name: "About",
  components: {
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
});
