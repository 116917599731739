
























import { isNasabah } from "@/apps/accounts/modules/store";
import { defineComponent } from "@vue/composition-api";
import APP_CONFIG from "../modules/config";
export default defineComponent({
  name: "Bantuan",
  components: {
    ViewHeader: () => import("@/apps/core/components/ViewHeader.vue"),
  },
  setup() {
    const urlBase = `${APP_CONFIG.baseS3Url}/bantuan/`;
    const openNewTab = (group: string) => {
      window.open(`${urlBase}panduan_${group}.pdf`, "_blank");
    }
    const classContent = isNasabah.value? "column no-padding is-full":"column is-full";
    return {
      // Data
      classContent,

      // Method
      openNewTab,
    };
  },
});
